
import React from 'react';

export default function SmsTerminals() {

  return (
    <React.Fragment>
            <h2>SMS-TERMINALS</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /sms/v2/sms-terminals
              </code>
            </pre>
            <p>
              Send an SMS with the code #*911# to all active restaurant/branch terminals. 
            </p>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    status: "complete",
    total: 33,
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>status</td>
                  <td>Status returned by Twilio</td>
                </tr>
                <tr>
                  <td>total</td>
                  <td>Total number of terminals to which the message was sent</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
