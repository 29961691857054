
import React from 'react';

export default function CallTest() {

  return (
    <React.Fragment>
            <h2>CALL-TEST</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /voice/test/call-test
              </code>
            </pre>
            <p>
              Make a programmable test call with the new order alert for restaurants. 
            </p>
            <p>
              To make use of this functionality you must make a POST call with the following parameters:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    phone: "(514) 333 7933",
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>phone</td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Valid destination phone number per country, with special characters and/or spaces (+()-).
                  <br/>Examples: 2223334444 or (222) 333-4444 or +12223334444.</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    sid: "CAd28d6d5f0d374a9da35c4da9",
    status: "completed",
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>sid</td>
                  <td>Identifier returned by Twilio</td>
                </tr>
                <tr>
                  <td>status</td>
                  <td>Status returned by Twilio</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
