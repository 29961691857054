
import React from 'react';

export default function OrderSmsTerminal() {

  return (
    <React.Fragment>
            <h2>ORDER-SMS-TERMINAL</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /sms/v2/order-sms-terminal
              </code>
            </pre>
            <p>
              Send an SMS to the restaurant's terminal with the code #*911#. 
            </p>
            <p>
              To make use of this functionality you must make a POST call with the following parameters:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    order: 45896,
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>order</td>
                  <td>Number</td>
                  <td>Yes</td>
                  <td>Order number in TastyBoom. 
                  <br/>Example: 45896</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    sid: "CAd28d6d5f0d374a9da35c4da9",
    status: "completed",
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>sid</td>
                  <td>Identifier returned by Twilio</td>
                </tr>
                <tr>
                  <td>status</td>
                  <td>Status returned by Twilio</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
