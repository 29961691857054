
import React, { useEffect } from 'react';
import CallTest from "../services/CallTest";
import SmsTest from "../services/SmsTest";
import AlertCall from "../services/AlertCall";
import OrderAlertCall from "../services/OrderAlertCall";
import DeliveryCanceledCall from "../services/DeliveryCanceledCall";
import OrderSmsTerminal from "../services/OrderSmsTerminal";
import BranchSmsTerminal from "../services/BranchSmsTerminal";
import SmsTerminals from "../services/SmsTerminals";
import RegisterCode from "../services/RegisterCode";
import CustomerSms from "../services/CustomerSms";
import DeliveryCanceledSms from "../services/DeliveryCanceledSms";

export default function Home() {

useEffect(() => {

	const script = document.createElement('script');
	script.src = "./assets/js/script.js";
	script.async = true;
	document.body.appendChild(script);

	return function cleanup() {
		document.body.removeChild(script);
	};
});

  return (
    <React.Fragment>
      <div className="left-menu">
        <div className="content-logo">
          <div className="logo">
            <img
              src="assets/images/logo.png"
              height="32"
            />
            <span>API ROBOCALL V2</span>
          </div>
          <button className="burger-menu-icon" id="button-menu-mobile">
            <svg width="34" height="34" viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              ></path>
              <path className="line line2" d="M 20,50 H 80"></path>
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              ></path>
            </svg>
          </button>
        </div>
        <div className="mobile-menu-closer"></div>
        <div className="content-menu">
          <div className="content-infos">
            <div className="info">
              <b>Version Documentation:</b> 1.2
            </div>
            <div className="info">
              <b>Last Updated:</b> 5 Nov, 2021
            </div>
          </div>
          <ul>
            <li className="scroll-to-link active" data-target="content-get-started">
              <a>GET STARTED</a>
            </li>
            <li className="scroll-to-link" data-target="content-authentication">
              <a>Authentication</a>
            </li>
            <li className="scroll-to-link" data-target="content-call-test">
              <a><span className="method method-post">POST</span>CALL-TEST</a>
            </li>
            <li className="scroll-to-link" data-target="content-sms-test">
              <a><span className="method method-post">POST</span>SMS-TEST</a>
            </li>
            <li className="scroll-to-link" data-target="content-alert-call">
              <a><span className="method method-post">POST</span>ALERT-CALL</a>
            </li>
            <li className="scroll-to-link" data-target="content-order-alert-call">
              <a><span className="method method-post">POST</span>ORDER-ALERT-CALL</a>
            </li>
            <li className="scroll-to-link" data-target="content-delivery-canceled-call">
              <a><span className="method method-post">POST</span>DELIVERY-CANCELED-CALL</a>
            </li>
            <li className="scroll-to-link" data-target="content-order-sms-terminal">
              <a><span className="method method-post">POST</span>ORDER-SMS-TERMINAL</a>
            </li>
            <li className="scroll-to-link" data-target="content-branch-sms-terminal">
              <a><span className="method method-post">POST</span>BRANCH-SMS-TERMINAL</a>
            </li>
            <li className="scroll-to-link" data-target="content-sms-terminals">
              <a><span className="method method-post">POST</span>SMS-TERMINALS</a>
            </li>
            <li className="scroll-to-link" data-target="content-register-code">
              <a><span className="method method-post">POST</span>REGISTER-CODE</a>
            </li>
            <li className="scroll-to-link" data-target="content-customer-sms">
              <a><span className="method method-post">POST</span>CUSTOMER-SMS</a>
            </li>
            <li className="scroll-to-link" data-target="content-delivery-canceled-sms">
              <a><span className="method method-post">POST</span>DELIVERY-CANCELED-SMS</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="content-page">
        <div className="content-code"></div>
        <div className="content">
          <div className="overflow-hidden content-section" id="content-get-started">
            <h1>Get started</h1>
            <pre>
            	<p style={{ color:'#fff', marginBottom:'0'}}>API Endpoints:</p>
            	<p style={{ color:'#fff', marginBottom:'0'}}>-For Canada https://robocallca.com/</p>
            	<p style={{ color:'#fff', marginBottom:'0'}}>-For Chile https://robocallcl.com/</p>
            </pre>
            <p>
              The Robocall API is a call/sms notification service with restricted access for 
              internal use by TastyBoom, which allows to manage phone alerts through the 
              Twillio communications platform.
            </p>
            <p>
              To use this API, it is necessary to contact the system administrators to obtain 
              the <strong>token</strong> that will allow you to have access to the functionalities, 
              as well as to request the unblocking of the <strong>IP access</strong> from which 
              you will be registering the conexion.
            </p>
          </div>
          <div className="overflow-hidden content-section" id="content-authentication">
            <h1>OAuth</h1>
            <pre> 'authorization: Bearer {`<token>`}' </pre>
            <p>
              All endpoints require an authorization header with a token value, 
              which must be like the following e.g. 
              <br/><br/>'authorization: Bearer eyJ0eXAiOiJKV1QiLCJey'.
            </p>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-call-test"
          >
          	<CallTest/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-sms-test"
          >
          	<SmsTest/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-alert-call"
          >
          	<AlertCall/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-order-alert-call"
          >
          	<OrderAlertCall/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-delivery-canceled-call"
          >
          	<DeliveryCanceledCall/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-order-sms-terminal"
          >
          	<OrderSmsTerminal/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-branch-sms-terminal"
          >
          	<BranchSmsTerminal/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-sms-terminals"
          >
          	<SmsTerminals/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-register-code"
          >
          	<RegisterCode/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-customer-sms"
          >
          	<CustomerSms/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-delivery-canceled-sms"
          >
          	<DeliveryCanceledSms/>
          </div>
        </div>
        <div className="content-code"></div>
      </div>
    </React.Fragment>
  );
}
